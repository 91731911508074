import React from "react";
import {isSafari} from "react-device-detect";
import Layout from "../_components/layout/layout";
import Contact from "../_components/contact/contact";



export default function ContactPage() {
    return (
        <Layout>
            <Contact className={`${isSafari && "safari"}`}/>
        </Layout>

    )
}
